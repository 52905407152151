<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div class="d-flex flex-wrap wrapper">
            <div class="sidebar" v-if="sideBar">
                <div class="align-items-end justify-content-end text-right text-white">
                    <h4 style="cursor:pointer;" @click="openSidebar">x</h4>
                </div>
                <div class="text-center">
                    <img :src="require('../assets/id-logo.png')" style="margin-bottom:40px;width:15em;" class="mx-auto text-center">
                </div>
                <div class="sidebar-link d-flex flex-wrap " @click="dashboard">
                    <img :src="require('../assets/icon-dash.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Dashboard</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="profile">
                    <img :src="require('../assets/profile.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Profile</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'main'}" @click="exitModal">
                    <img :src="require('../assets/transactions.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Transactions</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'changePin'}" @click="changePin">
                    <img :src="require('../assets/change pipn.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Change Pin</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap">
                    <img :src="require('../assets/support.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Support</h4>
                </div>
            </div>
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button" @click="openSidebar">
                            <v-icon name="chevron-right" />
                        </button>
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-center align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/notif.png')" alt="" class="topbar-img my-auto" style="margin-right:3em;">
                        <img :src="require('../assets/settings.png')" alt="" class="topbar-img my-auto" style="margin-right:3em;">
                        <div class="profile my-auto">
                            <h5 class="profile-name">{{ init.profile.fullName }}</h5>
                            <h6 class="profile-email">{{ init.profile.emailAddress }}</h6>
                        </div>
                    </div>
                </div>
                <div class="transaction-content"> 
                    <div class="history-header"> 
                        <span> RECENT TRANSACTIONS </span>
                    </div>
                    <div class="history-content">
                        <table>
                            <tr>
                                <th>{{ $t('blxDashboard.timestamp') }}</th>
                                <th>{{ $t('blxDashboard.hash') }}</th>
                                <th>{{ $t('blxDashboard.input') }}</th>
                                <th>{{ $t('blxDashboard.output') }}</th>
                                <th>AMOUNT</th> 
                                <th>{{ $t('blxDashboard.confirmations') }}</th> 
                                <th>{{ $t('blxDashboard.status') }}</th>
                            </tr>
                            <tr v-for="h in init.fund.history" :key="h.hash" @click="initView(h)">
                                <td >{{ formatDate(h.input.timeStamp) }}</td>
                                <td class="th-orange"> <div class="hash"><a :href="'https://explorer.blx.org/#/transaction/' + h.hash" target="_blank">{{ h.hash }}</a> </div> </td>
                                <td class="th-orange"> {{ h.senderWallet != null ? h.senderWallet.publicKey : "Newly generated CEZA" }}  </td>
                                <td class="th-orange">{{ h.recipient }}</td>
                                <td> CEZA {{ formatAmount(h.amount) }} </td> 
                                <td> {{ h.confirmations }} </td> 
                                <td v-if="h.confirmations >= 2">{{ $t('blxDashboard.completed') }}</td>
                                <td v-else>{{ $t('blxDashboard.pending') }}</td>
                            </tr>
                        </table>
                    </div> 
                </div>
            </div>
        </div>
        <ChangePin @close="exitModal"/>
        <ConfirmLogout />
    </div>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
export default {
    components: {
        ChangePin,
        ConfirmLogout
    },
    data() {
        return {
            sideBar: false,
            modalState: 'main',
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        openSidebar() {
            this.sideBar = !this.sideBar
        },
        privateKey() {
            this.modalState = 'privateKey'
        },
        changePin() {
            this.$bvModal.show('change-pin-modal'); 
            this.modalState = 'changePin'
        },
        exitModal() {
            this.modalState = 'main'
        },
        viewPrivateKey() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal');
        },
        importPrivateKey() {
            this.$bvModal.show('import-key-modal'); 
        },
        changeState(state) {
            this.state = state
        },
        profile() {
            this.$router.push('/profile')
        },
        dashboard() {
            this.$router.push('/')
        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/setup-profile";
                },1000)
                    
                }  

                })
        },
        getAddress: function(){
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/address/CEZA',
            };

            this.axios(options)
                .then((response) => {
                    this.init.address = response.data;
                    //console.log(this.init.address)
                    this.getFund();
                    this.getSubwalletInfo();
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },

        getFund: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
                url: '/blx-api/address/CEZA/' + this.init.address.addressValue,
            };

            this.axios(options)
                .then((response) => {
                
                this.init.fund = response.data;
                this.init.fund.history.forEach((value, index) => {
                    if(value.confirmations >= 2) {
                        console.log(value.confirmations)
                        this.status = "COMPLETED"
                    } else {
                        console.log('error')
                        this.status = "PENDING"
                    }

                });

                // console.log(this.init.fund.balance);

                this.init.fund.history.sort(function(a, b) {

                    return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
                });

                this.init.fund.history = this.init.fund.history.slice(0, 10);
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },


        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
                .then((response) => {
                
                this.init.profile = response.data;
    


                    
                })
        },
    },
    mounted() {
        this.checkSession();
        this.getAddress();
        let socketScript = document.createElement('script')
            socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
            document.head.appendChild(socketScript)
        console.log(this.init.fund.history.confirmations)
    }
}
</script>

<style scoped lang="scss">
    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                margin:20px auto;
                width: 15em;
                cursor:pointer;
                &.active {
                    border-right:2px #0e649e solid;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 100px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .transaction-content {
                margin-top: 3em;
            }
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
                color: #333;
            }
            .hash {
                white-space: nowrap !important;
                width: 20em;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
            }

            td, th {
                text-align: left;
                padding: 1em;
                font-size: 0.75em;
            }

            td {  
                background: #150754;
                color: #FFF;
                border-bottom: 1px solid #FFF;
                padding: 1.5em;
            }

            th {
                
                background: #110737;
                color: #0567d7;
                font-family:SSN-Bold;
            }

            th:first-child { 
                border-top-left-radius: 6px;
            }

            th:last-child { 
                border-top-right-radius: 6px;
            }

            .history-content {
                background: #FFF; 
                border-radius: 6px;
                    overflow-x:auto;
            }

            .history-container {  
                padding: 1.5em;
                border-radius: 6px;
                flex-direction: column;
                position: relative;
                background: #FFF;
                padding-top: 5em;
                color: #000;
                background-color: #de8413;
                background-image: linear-gradient(#de8413, #d8962c);
                width: 100%;
                margin: 0 2em;
            }
            
            .history-header {  
                color: #FFF;
                display: flex;
                justify-content: space-between;
                align-items: center;;
                a {
                    color: #FFF;
                    text-decoration: underline;
                }
            }
        }
    }
</style>